var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('div',{staticClass:"overflow-scroll"},[_c('div',{staticStyle:{"min-width":"1240px"}},[_c('a-card',{attrs:{"bordered":false}},[_c('a-form',{staticStyle:{"max-width":"400px"},attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"角色行动名称","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'name',
                {
                  rules: [{ required: true, message: '请输入' }],
                  initialValue: this.action.name
                }
              ]),expression:"[\n                'name',\n                {\n                  rules: [{ required: true, message: '请输入' }],\n                  initialValue: this.action.name\n                }\n              ]"}],attrs:{"placeholder":"请输入"}})],1),_c('a-form-item',{attrs:{"label":"角色行动说明","labelCol":_vm.labelCol,"wrapperCol":_vm.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'description',
                {
                  rules: [{ required: true, message: '请输入' }],
                  initialValue: this.action.description
                }
              ]),expression:"[\n                'description',\n                {\n                  rules: [{ required: true, message: '请输入' }],\n                  initialValue: this.action.description\n                }\n              ]"}],attrs:{"placeholder":"请输入"}})],1)],1)],1),_c('div',{staticStyle:{"margin-top":"8px"}},[_c('a-card',{attrs:{"bordered":false}},[_c('div',{staticClass:"steps-action"},[_c('a-button',{on:{"click":_vm.goBack}},[_vm._v("取消")]),_c('a-button',{staticStyle:{"margin-left":"8px"},attrs:{"type":"primary"},on:{"click":_vm.save}},[_c('a-spin',{attrs:{"spinning":_vm.saving}},[_vm._v("保存")])],1)],1)])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }