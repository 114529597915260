<template>
  <a-spin :spinning="loading">
    <div class="overflow-scroll">
      <div style="min-width: 1240px">
        <a-card :bordered="false">
          <a-form :form="form" style="max-width: 400px">
            <a-form-item
              label="角色行动名称"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
            >
              <a-input
                v-decorator="[
                  'name',
                  {
                    rules: [{ required: true, message: '请输入' }],
                    initialValue: this.action.name
                  }
                ]"
                placeholder="请输入"
              />
            </a-form-item>
            <a-form-item
              label="角色行动说明"
              :labelCol="labelCol"
              :wrapperCol="wrapperCol"
            >
              <a-input
                v-decorator="[
                  'description',
                  {
                    rules: [{ required: true, message: '请输入' }],
                    initialValue: this.action.description
                  }
                ]"
                placeholder="请输入"
              />
            </a-form-item>
          </a-form>
        </a-card>
        <div style="margin-top: 8px">
          <a-card :bordered="false">
            <div class="steps-action">
              <a-button @click="goBack">取消</a-button>
              <a-button type="primary" style="margin-left: 8px" @click="save">
                <a-spin :spinning="saving">保存</a-spin>
              </a-button>
            </div>
          </a-card>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Bus from "@/bus";
import {
  GET_ROLE_ACTIONS,
  UPDATE_ROLE_ACTION
} from "../../store/modules/actions.type";

export default {
  name: "editAction",

  created: async function() {
    this.loading = true;
    if (this.actions.length === 0) {
      await this.getRoleActions();
    }
    this.loading = false;
    Bus.$on("getTarget", () => {
      this.$router.push({
        path: "/authorization/actions/" + this.$route.params.actoinId
      });
    });
  },

  data() {
    return {
      loading: false,
      saving: false,

      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      },

      form: this.$form.createForm(this)
    };
  },

  computed: {
    ...mapGetters("roles", ["actions"]),

    action: function() {
      return (
        (this.actions &&
          this.actions.find(
            action => action.uuid === this.$route.params.actionId
          )) ||
        {}
      );
    }
  },

  methods: {
    ...mapActions("roles", [UPDATE_ROLE_ACTION, GET_ROLE_ACTIONS]),

    save() {
      let that = this;

      const {
        form: { validateFields }
      } = this;
      validateFields(async (errors, values) => {
        if (!errors) {
          that.saving = true;
          await that.updateRoleAction({
            ...that.action,
            ...values
          });
          that.saving = false;
          this.goBack();
        }
      });
    },

    goBack() {
      this.$router.push({ path: "/authorization/actions" });
    }
  }
};
</script>

<style scoped></style>
